'use strict';
/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */

 

module.exports = function () {
    if ($('.display-emppopup').length) {
       var url = $(".display-emppopup").data('url');
       $('.modalConsent').removeClass('d-none').modal({
           show: true,
           backdrop: 'static',
           keyboard: false
       });
       $("body").css("overflow", "hidden");
    
    
    }
    $('.continue-cln').click(function (e) {
        var url = $(this).data('url');
        $("body").css("overflow", "auto");
        $('body > .modal-backdrop').remove();
        sessionStorage.setItem("continueClicked", "yes");
        e.preventDefault();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.modalConsent').remove();
            },
            error: function () {
                $('.modalConsent').remove();
            }
        });
    });
};
