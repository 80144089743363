'use strict';

var activateSlickSlider = function () {
$('.homepage-product-listing').slick({
  		dots: false,
  		arrows:true,
  		infinite:true,
  		slidesToShow: 3,
  		slidesToScroll: 3,
  		responsive: [
    		{
      		breakpoint: 992,
      		settings: {
      		dots: true,
        	slidesToShow: 2,
        	slidesToScroll: 1
      		}
    	},
    	{
      		breakpoint: 580,
      		settings: {
      		dots: true,
        	slidesToShow: 1,
        	slidesToScroll: 1
      		}
    	}
        ]
   	});
   	if($('.product-detail.product-wrapper').length > 0 ) {
		if ($(window).width() < 1023) {
   			$('.homepage-product-listing').slick('unslick');
		}
	}
}

module.exports = {
 activateSlickSlider : activateSlickSlider

}